.container {
  background: #fff;
  display: flex;
}

.column_left {
  float: left;
  width: 50%;
  padding: 4rem;
}
.column_right {
  float: right;
  width: 50%;
}

.column_left h1 {
    text-align: center;
    color: #000000;
    font-size: 50px;
    font-family: "Koh Santepheap", serif;
    font-style: normal;
}

.column_left p {
    color: #000000;
    font-size: 20px;
    font-family: "Koh Santepheap", serif;
    font-weight: 500;
    font-style: normal;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 960px) {
  .column_left h1 {
    font-size: 30px;
  }
  .column_left p {
    font-size: 15px;
  }
  .container {
    flex-direction: column;
    margin-bottom: -10px;
  }
  .column_left {
    width: 100%;
    padding: 2rem;
  }
  .column_right {
    width: 100%;
  }
}
