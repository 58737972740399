.footer-container {
  background-color: #242222;
  padding: 2rem;
  justify-content: center;
  text-align: center;
}

.footer-container h1 {
  color: #fff;
  font-size: 30px;
  font-family: "Koh Santepheap", serif;
  font-style: normal;
}

@media screen and (max-width: 960px) {
  .footer-container {
    padding: 1rem;
  }
  .footer-container h1 {
    font-size: 20px;
  }
}